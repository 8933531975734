@charset 'UTF-8';
@use '../modules/global/' as *;



.top-topics-wrapper {
  padding-top: 60vw;
  position: relative;
  @include media(lg) {
    padding-top: 54vw;
  }
  @include media(xl) {
    padding-top: 54%;
  }
}



.top-topics-card-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
}


.top-topics-card {
  background-color: var(--white-color);
  border: 1px solid var(--gray-200-color);
  box-shadow: var(--box-shadow);
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  grid-column: auto / span 12;
  padding: 1.5rem;
  position: relative;
  @include containerQuery(600px) {
    grid-column: auto / span 6;
  }
  @include media(lg) {
    grid-column: auto / span 4;
  }
}



.topics-card-anchor {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.topics-card-image {
  overflow: hidden;
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}



.topics-card-headline {
  align-self: flex-end;
  font-size: 1.25em;
  font-weight: 700;
  span {
    display: block;
    font-size: .75em;
    font-weight: 500;
  }

}
