@charset 'UTF-8';
@use '../modules/global/' as *;



.top-bg-picture-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130vw;
  z-index: 0;
  @include media(md){
    height: 100vw;
  }
  @include media(lg){
    height: 56rem;
  }
  @include media(xl){
    height: 60rem;
  }
}



.top-bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: inherit;
}



.top-bg-headline {
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 30%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  h1 {
    font-size: clamp(1.4rem, 0.837rem + 2.404vw, 3rem);
    font-weight: 700;
    line-height: 1.6;
    text-shadow: 3px 3px 11px rgba(255, 255, 255, 1);
    position: absolute;
    top: min(24vw, 24rem);
    left: 5vw;
  }
}
