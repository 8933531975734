@charset 'UTF-8';
@use '../modules/global/' as *;



// NEWS
.top-news-wrapper {
  margin: var(--exlarge-spacing) 0 0;
}

// お知らせ一覧をみる
.list-page-wrapper {
  margin: 2rem 0 0;
  text-align: right;
}





// Banner swiper
.top-banner-wrapper {
  margin: var(--exlarge-spacing) 0 0;
}

ul.top-banner-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;
  li {
    flex: 0 0 224px;
  }
}
