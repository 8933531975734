@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.top-bg-picture-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130vw;
  z-index: 0;
}
@media (min-width: 768px) {
  .top-bg-picture-wrapper {
    height: 100vw;
  }
}
@media (min-width: 992px) {
  .top-bg-picture-wrapper {
    height: 56rem;
  }
}
@media (min-width: 1200px) {
  .top-bg-picture-wrapper {
    height: 60rem;
  }
}

.top-bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: inherit;
}

.top-bg-headline {
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 30%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.top-bg-headline h1 {
  font-size: clamp(1.4rem, 0.837rem + 2.404vw, 3rem);
  font-weight: 700;
  line-height: 1.6;
  text-shadow: 3px 3px 11px rgb(255, 255, 255);
  position: absolute;
  top: min(24vw, 24rem);
  left: 5vw;
}

.top-topics-wrapper {
  padding-top: 60vw;
  position: relative;
}
@media (min-width: 992px) {
  .top-topics-wrapper {
    padding-top: 54vw;
  }
}
@media (min-width: 1200px) {
  .top-topics-wrapper {
    padding-top: 54%;
  }
}

.top-topics-card-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
}

.top-topics-card {
  background-color: var(--white-color);
  border: 1px solid var(--gray-200-color);
  box-shadow: var(--box-shadow);
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  grid-column: auto/span 12;
  padding: 1.5rem;
  position: relative;
}
@container ( min-width: 600px ) {
  .top-topics-card {
    grid-column: auto/span 6;
  }
}
@media (min-width: 992px) {
  .top-topics-card {
    grid-column: auto/span 4;
  }
}

.topics-card-anchor {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.topics-card-image {
  overflow: hidden;
}
.topics-card-image img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.topics-card-headline {
  align-self: flex-end;
  font-size: 1.25em;
  font-weight: 700;
}
.topics-card-headline span {
  display: block;
  font-size: 0.75em;
  font-weight: 500;
}

.top-news-wrapper {
  margin: var(--exlarge-spacing) 0 0;
}

.list-page-wrapper {
  margin: 2rem 0 0;
  text-align: right;
}

.top-banner-wrapper {
  margin: var(--exlarge-spacing) 0 0;
}

ul.top-banner-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;
}
ul.top-banner-list li {
  flex: 0 0 224px;
}